import React from 'react';
import styled from 'styled-components';

// import { FaRegIdBadge, FaRegHandshake, FaUserShield, FaExpandAlt } from 'react-icons/fa';

import ContainerFixed from './Layouts/ContainerFixed';

import { StyledButtonLink } from './Button';

const ChooseUsWrapper = styled.div`
  padding: 4rem 0;

  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }

  a {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const WhyChooseUs = ({ heading, children }) => (
  <ChooseUsWrapper>
    <ContainerFixed>
      <h2>{heading}</h2>
      {children}
    </ContainerFixed>
    <ButtonWrapper>
      <StyledButtonLink to="/book-now">Get Started</StyledButtonLink>
    </ButtonWrapper>
  </ChooseUsWrapper>
);

export default WhyChooseUs;
