import React from 'react';

import CtaWrapper from '../CtaBlocks/CtaWrapper';
import CtaBlock from '../CtaBlocks/CtaBlock';

const ServicesLinks = () => (
  <CtaWrapper>
    <CtaBlock title="Regular Cleans">
      <p>
        The same familiar maid attends every regular weekly or fortnightly clean Assigned maid off
        on leave? We will send a lead cleaner in the interim if cover is still required. Our regular
        cleans keep your home sparkly clean and smelling fresh.
      </p>
    </CtaBlock>
    <CtaBlock title="Deep Cleans">
      <p>
        Deep cleans are very popular for students/ homeowners/ renters/ landlords alike. Property
        inspection due? Family event looming? Prioritise those problematic areas and add some shine
        and be sure to impress.
      </p>
    </CtaBlock>
    <CtaBlock title="End of Tenancy">
      <p>
        End of tenancy cleans can leave you feeling totally out of your depth. Help ensure you get
        that much needed tenancy deposit back or simply leave your home showhouse ready for the next
        tenants or new owners. Leave it to our professionals.
      </p>
    </CtaBlock>
    <CtaBlock title="Local Cleaners">
      <p>
        Hey neighbour! We have a band of mighty maids throughout all our service areas allowing our
        cleaners to work within a 5 mile radius of their own doorstep. Our 5 star rated maids love
        adding a Maidupnorth style sparkle to their local areas.
      </p>
    </CtaBlock>
  </CtaWrapper>
);

export default ServicesLinks;
