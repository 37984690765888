import React, { useState, useRef } from 'react';

import { FaPlus, FaMinus } from 'react-icons/fa';

import styled from 'styled-components';

const CollapseItem = styled.div``;

const CollapseHeader = styled.div`
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.light};
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    color: #000;
  }

  @media (max-width: 500px) {
    svg {
      margin-left: 1rem;
    }
  }
`;

const CollapseContent = styled.div`
  overflow: hidden;
  max-height: ${(props) => props.height};
  transition: 0.3s all ease-in-out;
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
`;

/*
    Issues with content showing on hidden element.
    Might be better way to do this.
*/
const Content = styled.div`
  padding-top: 1rem;
`;

function Collapse({ title, children }) {
  const [isActive, isActiveState] = useState('');
  const [setHeight, setHeightSate] = useState('0px');

  const content = useRef(null);

  function toggleCollapse() {
    isActiveState(isActive === '' ? 'active' : '');
    setHeightSate(isActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
  }

  function RenderIcon() {
    return isActive === 'active' ? <FaMinus size="10px" /> : <FaPlus />;
  }

  return (
    <CollapseItem>
      <CollapseHeader onClick={toggleCollapse}>
        <h3>{title}</h3>
        <IconWrapper>
          <RenderIcon />
        </IconWrapper>
      </CollapseHeader>
      <CollapseContent height={setHeight} ref={content}>
        <Content>{children}</Content>
      </CollapseContent>
    </CollapseItem>
  );
}

export default Collapse;
