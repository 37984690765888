import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledCarouselWrapper = styled.div`
  margin: 5rem 0 0 0;

  h2 {
    text-align: center;
  }

  .slick-track {
    display: flex;
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${(props) => props.theme.primary};
  }
`;

const Intro = styled.p`
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 650px) {
    max-width: 90%;
  }
`;

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: 'block', background: '#fff' }}
      onClick={onClick}
      onKeyPress={onClick}
      type="button"
      aria-label="Next"
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: 'block', background: '#fff' }}
      onClick={onClick}
      onKeyPress={onClick}
      type="button"
      aria-label="Prev"
    />
  );
}

export default function SimpleSlider({ items, title, intro, children }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: items || 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <StyledCarouselWrapper>
      <h2>{title}</h2>
      {intro && <Intro>{intro}</Intro>}
      <Slider {...settings}>{children}</Slider>
    </StyledCarouselWrapper>
  );
}
