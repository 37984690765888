import React from 'react';
import styled from 'styled-components';

import { FaStar } from 'react-icons/fa';

const StyledReview = styled.a`
  color: white;
  svg {
    color: #f5d846;
  }
`;

const Reviews = () => (
  <StyledReview
    href="https://www.google.com/search?sxsrf=ALeKk03oZbWdnQMaxOcr1T2k0Ib29GYEQg:1589480452330&q=Maid+Up+North&spell=1&sa=X&ved=2ahUKEwjQ9fON_LPpAhXxnVwKHVbmBXYQBSgAegQIExAn&biw=1920&bih=937#lrd=0x487e70c82178b3ef:0xfb7162f117a4760,1,,,"
    target="_blank"
  >
    <FaStar /> 4.9 Stars >70 Reviews
  </StyledReview>
);

export default Reviews;
