import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import Carousel from '../Carousel';
import Testimonial from './Testimonial';

const Wrapper = styled.div`
  padding-bottom: 5rem;
`;

const SupportingText = styled.p`
  max-width: 50%;
  margin: 0 auto;
  padding-top: 4rem;
  font-size: 90%;
  text-align: center;

  @media (max-width: 650px) {
    max-width: 90%;
  }
`;

const Testimonials = () => (
  <StaticQuery
    query={graphql`
      {
        page: contentfulPage(slug: { eq: "testimonials" }) {
          title
          slug
          testimonials {
            id
            name
            image {
              file {
                url
              }
            }
            childContentfulCarouselTextRichTextNode {
              id
              json
            }
            __typename
          }
        }
      }
    `}
    render={(data) => (
      <Wrapper>
        <Carousel
          title="Customer Testimonials"
          intro="Our Canny Clients know the score… They know the quality of our service delivery better than anyone."
        >
          {data.page.testimonials.map((person) => (
            <Testimonial key={person.id} image={person.image.file.url} name={person.name}>
              {documentToReactComponents(person.childContentfulCarouselTextRichTextNode.json, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                },
              })}
            </Testimonial>
          ))}
        </Carousel>
        <SupportingText>
          Customer satisfaction is paramount at Maidupnorth. Feedback allows us to grow positively
          as a caring, cleaning community. Our extended Maidupnorth family is in the heart of
          everything we do!
        </SupportingText>
      </Wrapper>
    )}
  />
);

export default Testimonials;
