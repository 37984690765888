import { Link, StaticQuery, graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Section from '../Section';
import Block from '../Block';
import ContainerFixed from '../Layouts/ContainerFixed';

import Tag from './Tag';

export const StyledContainer = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  margin: 24px auto;
  .content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }
`;

const LocationsList = () => (
  <StaticQuery
    query={graphql`
      query LocationsListQuery {
        allContentfulLocations(sort: { fields: [sortOrder], order: ASC }) {
          nodes {
            id
            location
            slug
            areasCovered
            heroImage {
              fixed(height: 200) {
                ...GatsbyContentfulFixed
              }
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulLocations: locations }) => (
      <div>
        <Section heading="Locations we cover" contained>
          <p>
            All our cleaning services are currently available throughout the North East serving
            Newcastle, Gateshead, North Tyneside, and surrounding areas.
          </p>
        </Section>
        <ContainerFixed>
          <Block columns="1fr 1fr 1fr" breakpoint="995px">
            {locations.nodes.map((location) => (
              <StyledContainer key={location.id}>
                {location.heroImage && (
                  <Link style={{ width: '100%' }} to={`/locations/${location.slug}`}>
                    <Img
                      style={{ width: '100%' }}
                      fixed={location.heroImage.fixed}
                      alt={location.location}
                    />
                  </Link>
                )}
                <div className="content">
                  <h2>
                    <Link to={`/locations/${location.slug}`}>{location.location}</Link>
                  </h2>
                  <p>Surrounding Areas</p>
                  <p>
                    {location.areasCovered.map((area) => (
                      <Tag key={area}>{area}&nbsp;</Tag>
                    ))}
                  </p>
                </div>
              </StyledContainer>
            ))}
          </Block>
        </ContainerFixed>
      </div>
    )}
  />
);

export default LocationsList;
