import React from 'react';

import styled from 'styled-components';

import Section from './Section';
import Block from './Block/index';

import GuardianLogo from '../images/testimonials/logos/guardian.svg';
import ThreeBestLogo from '../images/testimonials/logos/three-best-rated-award.png';

const SmallImg = styled.img`
  max-height: 50px;
  margin: 1rem auto;
`;

const PressReviews = () => (
  <Section>
    <Block>
      <div>
        <SmallImg src={GuardianLogo} alt="The Guardian" />
        <p>
          "A cleaning service that is quick to book online and fits around customers busy
          lifestyles."
        </p>
      </div>
      <div>
        <SmallImg src={ThreeBestLogo} alt="Three best rated award" />
        <p>
          "Proud to be a Three Best Rated company for cleaning services in Newcastle and Gateshead."
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://threebestrated.co.uk/cleaning-services-in-newcastle-upon-tyne"
        >
          <b>Read more</b>
        </a>
      </div>
    </Block>
  </Section>
);

export default PressReviews;
