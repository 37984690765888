import React from 'react';

import { FaCalendarAlt, FaTrash, FaBed } from 'react-icons/fa';

import { theme } from '../styles/GlobalStyle';

import Section from './Section';
import Block from './Block';

const IconStyle = {
  fontSize: '4rem',
  marginBottom: '1rem',
  color: theme.secondary,
};
const Process = () => (
  <Section>
    <h2>How Maidupnorth Works</h2>
    <br />
    <Block columns="1fr 1fr 1fr">
      <div>
        <FaCalendarAlt style={IconStyle} />
        <h3>Book</h3>
        <p>
          Book your preferred slot and frequency of clean online. We'll then confirm your booking
          through email.
        </p>
      </div>
      <div>
        <FaTrash style={IconStyle} />
        <h3>Clean</h3>
        <p>One of our vetted cleaners will come and clean your home to a prescribed checklist.</p>
      </div>
      <div>
        <FaBed style={IconStyle} />
        <h3>Relax</h3>
        <p>
          Payment will be taken after the clean is completed. Sit back and enjoy your freshly
          cleaned home!
        </p>
      </div>
    </Block>
  </Section>
);

export default Process;
