import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../shared/components/Layouts/Inner';
import ContainerFixed from '../../shared/components/Layouts/ContainerFixed';
import SEO from '../../shared/components/SEO';
import Hero from '../../shared/components/Hero/index';
import HeroImg from '../../shared/images/banners/placeholder.jpg';
import Block from '../../shared/components/Block';
import PressReviews from '../../shared/components/PressReviews';
import WhyChooseUs from '../../shared/components/WhyChooseUs';
import Process from '../../shared/components/Process';
import ServicesInfo from '../../shared/components/Services/ServicesInfo';
import Professionals from '../../shared/components/Testimonial/Professionals';
import Faq from '../../shared/components/Collapse/Faq';
import Testimonials from '../../shared/components/Testimonial/Testimonials';
import AppDownload from '../../shared/components/AppDownload';
import LocationsList from '../../shared/components/Locations/List';

export const query = graphql`
  {
    page: contentfulPage(slug: { eq: "regular-home-cleaning" }) {
      title
      slug
      frequentlyAskedQuestions {
        id
        title
        childContentfulAccordionBodyRichTextNode {
          id
          json
        }
        __typename
      }
      cleaningProfessionals {
        id
        name
        image {
          file {
            url
            fileName
            contentType
          }
        }
        childContentfulCarouselTextRichTextNode {
          id
          json
        }
        __typename
      }
    }
  }
`;

const RegularHome = ({ data }) => (
  <Layout url="domestic-house-cleans" darkMode>
    <SEO title="Regular Home Cleaning" />
    <Hero heading="Regular Cleaning" image={HeroImg} showReviews>
      <p>
        Nothing beats coming home to a sparkling fresh home after work. Our canny clients love
        having the same familiar maid attend weekly or fortnightly.
      </p>
    </Hero>
    <PressReviews />
    <WhyChooseUs heading="Regular Cleans">
      <Block>
        <div>
          <h3>Professional Experience</h3>
          <p>
            All regular service cleaners have been specially trained, vetted and are fully insured.
            We only select regular maids who have graduated from our induction program. Cleaning
            someone`s home or office is a position of trust which requires professionalism,
            discretion and respect at all times. Cleaning requires experience and attention to
            detail so our Mighty maids take great pride in wearing our uniform and delivering a
            guaranteed 5 star service to all their regular clients on a weekly or fortnightly basis.
          </p>
        </div>
        <div>
          <h3>Highly Proficient</h3>
          <p>
            By providing the same maid/ time/day of clean our maids are able to pay attention to
            detail and nurture their cleans. Knowing their regular clients needs and homes allows
            them to deliver a high end quality assured clean at every visit allowing our customers
            to relax and forget about those household cleaning chores that can be very time
            consuming and mundane.
          </p>
        </div>
      </Block>
      <br />
      <Block>
        <div>
          <h3>Reliablility</h3>
          <p>
            Choosing a regular service is choosing a service you can trust to maintain your home to
            an ongoing sparkly clean level you can take pride in. We have provided our maids with
            geo sensitive apps that record their clean duration and their location. Maidupnorth
            understands how important it is for our customers to have the same familiar maid on the
            same day and time at each routinal visit. Familiarity of your home and lifestyle needs
            builds trust and firm foundations between you, your assigned maid and us as a company.
          </p>
        </div>
        <div>
          <h3>Flexibility</h3>
          <p>
            Life can be extremely hectic with lots of balls to juggle not mentioning those curve
            balls that tend to arrive unexpectedly. As a family run cleaning company we understand
            the need for flexibility. If you need to reschedule you can micro manage your booking
            from your fingertips on the go. If in doubt or within 48 hrs of your scheduled booking
            please give us a call or drop us an email and our customer service team will be very
            happy to help.
          </p>
        </div>
      </Block>
    </WhyChooseUs>
    <Process />
    <ServicesInfo />
    <Professionals
      heading="Say hi to some of our Regular Cleaners"
      data={data.page.cleaningProfessionals}
    />

    <ContainerFixed>
      <Faq data={data.page.frequentlyAskedQuestions} />
    </ContainerFixed>

    <LocationsList />

    <ContainerFixed>
      <Testimonials />
      <AppDownload />
    </ContainerFixed>
  </Layout>
);

export default RegularHome;
