import React from 'react';
import { FaPencilAlt, FaRegBell } from 'react-icons/fa';

import styled from 'styled-components';

import { theme } from '../styles/GlobalStyle';

import Block from './Block';

import AppImg from '../images/appDownload/iphone@2x.jpg';
import Apple from '../images/appDownload/apple-store.svg';
import Android from '../images/appDownload/play-store.svg';

const DownloadSection = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;

  @media (max-width: 770px) {
    display: block;

    .phone {
      display: none;
    }
  }
`;

const SmallLogo = styled.img`
  max-width: 125px;
  display: inline-block;
  margin-top: 0.5rem;
  &:last-child {
    margin-left: 0.5rem;
  }
`;

const VerticalAlign = styled.p`
  display: flex;
  align-items: center;
`;

const AppDownload = () => (
  <DownloadSection>
    <img src={AppImg} className="phone" alt="iPhone app" />
    <div>
      <h2>Download the Launch 27 App</h2>
      <p>
        <b>Manage your Bookings with Maid Up North on the go!</b>
      </p>
      <p>
        Launch 27 is the easiest way to track and manage your Maid Up North cleaners. Simply
        download the app and log in with your Maid Up North web account and book your appointment!
      </p>
      <br />
      <Block breakpoint="1000px">
        <div>
          <VerticalAlign>
            <FaPencilAlt size={25} color={theme.primary} style={{ marginRight: '15px' }} />
            <b>Edit Your Bookings</b>
          </VerticalAlign>

          <ul>
            <li>Update your cleaning checklist online</li>
            <li>Cancel bookings online with no lengthy contracts.</li>
            <li>Change payment details for your clean online.</li>
            <li>Rebook a previous or new cleaning service.&nbsp;</li>
          </ul>
        </div>
        <div>
          <VerticalAlign>
            <FaRegBell size={25} color={theme.primary} style={{ marginRight: '15px' }} />
            <b>Receive Updates</b>
          </VerticalAlign>
          <ul>
            <li>Alerted when cleaner starts and finishes job</li>
            <li>See what items have been cleaned on your last visit</li>
            <li>See if cleaners are running late</li>
          </ul>
        </div>
      </Block>
      <div>
        <a
          href="https://apps.apple.com/us/app/launch-27/id1166954246"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SmallLogo src={Apple} alt="ios Download" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.launch27.teams&hl=en_GB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SmallLogo src={Android} alt="Android Download" />
        </a>
      </div>
    </div>
  </DownloadSection>
);

export default AppDownload;
