import React from 'react';
import styled from 'styled-components';

import { StyledButtonLink } from '../Button';

const StyledInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
`;

const StyledCtaWrapper = ({ children }) => <StyledInner>{children}</StyledInner>;

const StyledBoxWrapper = styled.div`
  background-color: rgba(247, 251, 252, 0.6);
  border: 10px solid ${(props) => props.theme.primary};
  border-radius: 50px;
  padding: 3rem 2rem;
  text-align: center;

  @media (max-width: 715px) {
    padding: 1rem 0.5rem;
  }

  hr {
    width: 50px;
    border: 0;
    border-bottom: 3px solid;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1250px) {
    text-align: center;
    flex-wrap: wrap;
  }

  text-align: left;
`;

const CtaWrapper = ({ children }) => (
  <StyledCtaWrapper>
    <StyledBoxWrapper>
      <ItemWrapper id="services">{children}</ItemWrapper>
      <br />
      <StyledButtonLink to="/book-now">Book Now</StyledButtonLink>
      <br />
    </StyledBoxWrapper>
  </StyledCtaWrapper>
);

export default CtaWrapper;
