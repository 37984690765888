import React from 'react';
import styled from 'styled-components';

import { StyledOutlineButtonLink } from '../Button';

const StyledCtaBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  margin-bottom: 2rem;

  @media (max-width: 715px) {
    flex-basis: 100%;
    padding: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    margin: auto;
    padding: 20px;
  }

  div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const CtaBlock = ({ title, children, image, page }) => (
  <StyledCtaBlock title={title}>
    {image && <img src={image} alt={title} />}
    <div>
      <h2>{title}</h2>
      {children}
    </div>
    {page && <StyledOutlineButtonLink to={page}>Find out more</StyledOutlineButtonLink>}
  </StyledCtaBlock>
);

export default CtaBlock;
