import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTestimonial = styled.div`
  text-align: center;
  max-width: 75%;
  margin: 0 auto;
`;
const Headshot = styled.img`
  width: 100%;
  max-width: 350px;
  max-height: 350px;
  border-radius: 100%;
  border: 5px solid ${(props) => props.theme.primary};
  padding: 1rem;
  margin: 0 auto 2rem auto;
  overflow: hidden;
`;

const Testimonial = ({ image, name, title, children }) => (
  <StyledTestimonial>
    <Headshot src={image} alt={name} />
    <div>
      <h3>{name}</h3>
      {title && <h4>{title}</h4>}
      {children}
    </div>
  </StyledTestimonial>
);

Testimonial.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default Testimonial;
