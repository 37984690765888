/* eslint no-underscore-dangle: 0 */

import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import Collapse from './index';

const FaqWrapper = styled.div`
  margin: 3rem;

  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
`;

const Faq = ({ data }) => {
  if (!data) return null;
  return (
    <FaqWrapper>
      <h2>Frequently Asked Questions</h2>

      {data.map((faq) => {
        if (faq.__typename !== 'ContentfulAccordion') return null;
        return (
          <Collapse key={faq.id} title={faq.title}>
            {documentToReactComponents(faq.childContentfulAccordionBodyRichTextNode.json, {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              },
            })}
          </Collapse>
        );
      })}
    </FaqWrapper>
  );
};

export default Faq;
