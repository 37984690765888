import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import ContainerFixed from '../Layouts/ContainerFixed';
import Reviews from './Reviews';

import { StyledButtonLink } from '../Button';

const Hero = styled.div`
  width: 100%;
  background: url(${(props) => props.bgImage}) no-repeat center/cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .hero-wrapper {
    position: relative;
    max-width: 1200px;
    padding: 6rem;
    color: white;

    @media (max-width: 750px) {
      padding: 5rem 0;
    }

    p {
      font-size: 1.15rem;
      font-weight: 500;
    }

    .tags {
      p {
        font-size: 1rem;
      }
    }

    ul {
      list-style-type: none;
      display: flex;
      justify-content: center;
      padding-left: 0;
      li {
        padding: 0 1rem;
        font-weight: 500;
        svg {
          margin-right: 0.5rem;
          color: #9dca3b;
        }
      }
    }
  }
  .hero-heading {
    color: white;
  }
`;

const ButtonWrapper = styled.div`
  a {
    margin: 0 0.5rem;

    @media (max-width: 500px) {
      width: 60%;
      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const StyledScrollButton = styled(Link)`
  cursor: pointer;
  display: inline-block;
  background-color: white;
  color: ${(props) => props.theme.primary};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem 2rem;
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => props.theme.primary};
    color: white;
    border: 1px solid ${(props) => props.theme.primary};
  }
`;

const HeroBanner = ({ heading, image, hideBookBtn, hideServicesBtn, showReviews, children }) => (
  <Hero bgImage={image}>
    <ContainerFixed>
      <div className="hero-wrapper">
        <h1 className="hero-heading">{heading}</h1>
        {children}
        {showReviews && (
          <p>
            <Reviews />
          </p>
        )}
        <ButtonWrapper>
          {!hideBookBtn && <StyledButtonLink to="/book-now">Book Now</StyledButtonLink>}

          {!hideServicesBtn && (
            <StyledScrollButton to="services" smooth offset={-100}>
              Our Services
            </StyledScrollButton>
          )}
        </ButtonWrapper>
      </div>
    </ContainerFixed>
  </Hero>
);

export default HeroBanner;
