import styled from 'styled-components';

const Tag = styled.span`
  background-color: ${(props) => props.theme.light};
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  display: inline-block;
  font-size: 75%;
  border-radius: 3px;
  color: #000;

  &:last-child {
    margin-right: 0;
  }
`;

export default Tag;
