import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import { FaStar } from 'react-icons/fa';

import Section from '../Section';
import Carousel from '../Carousel';
import Testimonial from './Testimonial';

const IconStyle = {
  color: '#f5d846',
};

function getStarRating(count) {
  const ratingCount = [];

  for (let x = 0; x < count; x += 1) {
    ratingCount.push(<FaStar key={x} style={IconStyle} />);
  }

  return ratingCount;
}

const Professionals = ({ data, heading }) => (
  <Section heading={heading || 'Our cleaning professionals'}>
    <Carousel items={data.length}>
      {data.map((person) => (
        <Testimonial key={person.id} image={person.image.file.url} name={person.name}>
          <p>{getStarRating(5)}</p>
          {documentToReactComponents(person.childContentfulCarouselTextRichTextNode.json, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            },
          })}
        </Testimonial>
      ))}
    </Carousel>
  </Section>
);

export default Professionals;
